/** @jsx jsx */
import { FinnoscoreComparisonPage } from '@composite';
import { useDynamicSelection } from '@modules';
import { jsx } from 'theme-ui';

export default () => {
  const { error, isLoading, ...selectionProps } = useDynamicSelection('telco');

  return <FinnoscoreComparisonPage pageContext={selectionProps} isLoading={isLoading} error={error} />;
};
